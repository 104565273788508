import { IonBadge, IonButton, IonIcon, IonSpinner, useIonAlert } from '@ionic/react'
import clsx from 'clsx'
import { forwardRef, useCallback } from 'react'

import './style.scss'
import { useTranslation } from 'react-i18next'

export interface ButtonI {
  icon?: string
  iconSrc?: string
  loading?: boolean
  round?: boolean
  badge?: string | number
  buttonSize?: 'small' | 'default' | 'large'
  alertText?: string
}

export type ButtonProps = React.ComponentProps<typeof IonButton> & ButtonI

const Button: React.FC<ButtonProps> = forwardRef<HTMLIonButtonElement, ButtonProps>(({
  children,
  onClick: oC,
  loading = false,
  disabled,
  className,
  icon,
  iconSrc,
  round,
  badge,
  size,
  buttonSize,
  alertText,
  ...ionButtonProps
}, ref) => {
  const [present] = useIonAlert()
  const { t } = useTranslation()

  const handleOnClick = useCallback((e: any) => {
    if (!alertText && oC) {
      oC(e)
    } else if (alertText) {
      present({
        message: alertText,
        buttons: [
          t('buttons.cancel'),
          { text: t('buttons.ok'), handler: oC }
        ]
      })
    }
  }, [alertText, oC, present, t])

  return (
        <IonButton
            onClick={handleOnClick}
            ref={ref}
            {...ionButtonProps}
            size={buttonSize}
            className={clsx(className, 'jmt-button', { 'jmt-button--has-content': !!children, 'jmt-button--round': round })}
            disabled={disabled ?? loading}
        >
            {loading
              ? (
                <IonSpinner
                    className='jmt-button__loading-spinner'
                />
                )
              : icon
                ? (
                <IonIcon
                    icon={icon}
                    size={size}
                />
                  )
                : iconSrc
                  ? (
                <IonIcon
                    src={iconSrc}
                    size={size}
                />
                    )
                  : null}
            <div
                className='jmt-button__label'
            >
                {children}
            </div>
            {badge && (
                <IonBadge
                    className='jmt-button__badge'
                >
                    {badge}
                </IonBadge>
            )}
        </IonButton>
  )
})

Button.displayName = 'Button'

export default Button
