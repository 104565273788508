import './style.scss'
import { type Camp, CampsDocument, useCampsQuery, useDeleteCampMutation, useEditCampMutation } from '../../../lib/apollo/types'
import AdminCampCard from './AdminCampCard'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { endOfYear, format, startOfYear } from 'date-fns'
import { IonText } from '@ionic/react'
import Modal from '../../Modal'
import Form from '../../../components/Form'
import FormSlider from '../../Form/FormSlider'
import Buttons from '../../Buttons'
import FormImageInput from '../../Form/FormImageInput'
import { Capacitor } from '@capacitor/core'
import Button from '../../Button'
import { save } from 'ionicons/icons'
import FormBirthdateField from '../../Form/FormBirthdateField'
import FormTextField from '../../Form/FormTextField'

let today = startOfYear(new Date())
const firstJuly = new Date(2023, 6, 1)
if (today < firstJuly) {
  today = firstJuly
}
const endDate = endOfYear(new Date())

const AllAdminCamps: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useCampsQuery({
    variables: {
      start: format(today, 'yyyy-MM-dd'),
      end: format(endDate, 'yyyy-MM-dd')
    },
    fetchPolicy: 'cache-and-network'
  })
  const [activeCamp, setActiveCamp] = useState<Camp>({ id: '' })

  const [ageMin, setMinAge] = useState(null)
  const [ageMax, setMaxAge] = useState(null)
  const [preview, setPreview] = useState<string | undefined>()
  const [editCampMutation] = useEditCampMutation()

  const handleMinAgeChange = (event: any) => {
    const newValue = event.detail.value
    setMinAge(newValue)
    return ageMin
  }

  const handleMaxAgeChange = (event: any) => {
    const newValue = event.detail.value
    setMaxAge(newValue)
    return ageMax
  }

  const editCamp = useCallback(async ({ ageMax, ageMin, caption, start, end, image, number, type }: { ageMax?: number, ageMin?: number, caption?: string, end?: string, image?: string, number?: string, start?: string, type?: string }) => {
    await editCampMutation({
      variables: {
        input: {
          ageMax: Number(ageMax),
          ageMin: Number(ageMin),
          caption,
          end,
          image,
          number,
          start,
          type
        },
        campId: activeCamp.id
      },
      refetchQueries: [{
        query: CampsDocument,
        variables: {
          campId: activeCamp.id
        }
      }]
    }
    )
    setActiveCamp({ id: '' })
  }, [editCampMutation, ageMin, ageMax])

  const [deleteCamp] = useDeleteCampMutation()

  const onDeleteClick = useCallback(async (camp: Camp) => {
    await deleteCamp({
      variables: {
        campId: camp.id
      },
      refetchQueries: [{
        query: CampsDocument,
        variables: {
          start: format(today, 'yyyy-MM-dd'),
          end: format(endDate, 'yyyy-MM-dd')
        }
      }]
    })
  }, [deleteCamp])

  const setEditCampId = (selectedCampId: string) => {
    setActiveCamp(data?.camps.find(({ id }) => id === selectedCampId) ?? { id: '' })
  }
  const setDeleteCampId = (selectedCampId: string) => {
    onDeleteClick(data?.camps.find(({ id }) => id === selectedCampId) ?? { id: '' })
  }

  const campsGroupedByYearAndMonth = useMemo(() => {
    const campsGroupedByYearAndMonth: Record<string, any[]> = {}
    data?.camps?.forEach(camp => {
      if (!camp.start) return
      const date = new Date(camp.start)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const key = `${year}-${String(month).padStart(2, '0')}`
      if (!campsGroupedByYearAndMonth[key]) {
        campsGroupedByYearAndMonth[key] = []
      }
      campsGroupedByYearAndMonth[key].push(camp)
    })
    return campsGroupedByYearAndMonth
  }, [data?.camps])

  return (
    <>
        <div className='camps__title'>
            <h2>{t('camps.all.title')}</h2>
            <IonText color='medium'>{t('camps.all.description')}</IonText>
        </div>
        {Object.entries(campsGroupedByYearAndMonth ?? {}).map(([monthYear, camps]) => {
          // Destructure monthYear into year and month here
          const [year, month] = monthYear.split('-')

          return (
                <div className={'admin-camps__title'}
                    key={monthYear}
                >
                <h3>{t(`animation_label.m.${month}`, { year })} </h3>
                    {camps.map(camp => (
                        <div key={camp.id}>
                          <AdminCampCard camp={camp} setEditId={() => { setEditCampId(camp.id) }} setDeleteId={setDeleteCampId}/>
                        </div>
                    ))}
                </div>
          )
        })}
          <Modal
                modalTitle={t('camp.edit.title')}
                size='alert'
                isOpen={!!activeCamp?.id}
                onClose={() => { setActiveCamp({ id: '' }) }}
                onDidDismiss={() => { setActiveCamp({ id: '' }) }}
            >
                <div className='create-group-modal'>
                    <Form onSubmit={ editCamp } values={activeCamp}>
                      <FormSlider
                          key={`min_ ${Date.now()}`}
                          onChange={(e) => { handleMinAgeChange(e) } }
                          defaultValue={0}
                          color='primary'
                          label={t('camp.edit.minAgeInput.label')}
                          info={ t('camp.edit.maxAgeInput.tip') }
                          name='ageMin'
                          min={4}
                          max={18}
                          required
                      />
                  <h3
                      className='talent-vote-form__value'
                  >
                  {ageMin ?? activeCamp.ageMin}
                  </h3>
                  <FormSlider
                          key={`max_ ${Date.now()}`}
                          onChange={(e) => { handleMaxAgeChange(e) } }
                          defaultValue={18}
                          color='primary'
                          label={t('camp.edit.maxAgeInput.label')}
                          info={ t('camp.edit.maxAgeInput.tip') }
                          name='ageMax'
                          min={4}
                          max={18}
                          required
                      />
                  <h3
                      className='talent-vote-form__value'
                  >
                  {ageMax ?? activeCamp.ageMax}
                  </h3>
                      <Buttons>
                        <FormImageInput
                            name='image'
                            label={Capacitor.isNativePlatform() ? 'make image' : 'upload image' }
                            size={{ width: 800, height: 800 }}
                            onPreviewChange={p => {
                              setPreview(p)
                            }}
                            showPreview={false}
                            avatarWrapperClassName='avatar-wrapper--for-talent'
                        />
                      <Button
                          type='submit'
                          icon={save}
                          disabled={!preview}
                      >
                      {t('button.save')}
                      </Button>
                      </Buttons>
                      <FormBirthdateField
                                              name='start'
                                              label={t('camp.edit.start.label')}
                                              value={activeCamp.start}
                                          />
                      <FormBirthdateField
                                              name='end'
                                              label={t('camp.edit.end.label')}
                                              value={activeCamp.end}
                                          />
                      <FormTextField name='caption' label={t('camp.edit.start.label')} required />
                      <FormTextField name='number' label={t('camp.edit.number.label')} required />
                      <FormTextField name='type' label={t('camp.edit.type.label')} required />
                      <Button type='submit' className='ion-float-end'>{t('button.save')}</Button>
                    </Form>
                </div>
            </Modal>
    </>
  )
}

export default AllAdminCamps
