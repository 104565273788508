import { useWhoamiQuery } from '../../lib/apollo/types'
import { Route, Redirect, type RouteProps } from 'react-router-dom'

interface ProtectedRouteProps extends RouteProps {
  role: string
  redirectTo: string
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, role, redirectTo, ...props }) => {
  const Component = component ?? (() => null)
  const { data, loading } = useWhoamiQuery(
    {
      fetchPolicy: 'cache-and-network'
    }
  )
  if (loading) return null
  const isAuthorized = data?.whoami?.user && data?.whoami?.user.roles.includes(role)
  return <Route {...props} render={(routeProps) =>
    isAuthorized ? <Component {...routeProps} /> : <Redirect to={redirectTo} />
  } />
}

export default ProtectedRoute
