import './style.scss'
import '../../components/Camps/Participant.scss'
import { useTranslation } from 'react-i18next'
import Page from '../../components/Page'
import Content from '../../components/Content'
import Header from '../../components/Header'
import {
  CampDocument,
  CampsDocument,
  type Group,
  useCampQuery,
  useCreateGroupMutation,
  useParticipantsQuery,
  useSpecialTalentsQuery,
  useEditCampDirectorMutation,
  useEditCampMutation,
  useDeleteCampMutation,
  useAddTrainerMutation,
  type Participant as GqlParticipant,
  type Trainer,
  useAddMultipleToGroupMutation,
  useAddParticipantMutation,
  useMarkedSpecialTalentsQuery,
  useRerunPersistCertificateMutation,
  useRemoveAllParticipantsFromCampMutation,
  ParticipantsDocument,
  useRemoveParticipantMutation,
  useEditParticipantMutation,
  useImportParticipantsMutation,
  useImportTrainersMutation,
  useRemoveTrainerFromCampMutation,
  useEditTrainerInCampMutation
  // useEditTrainerInCampMutation
} from '../../lib/apollo/types'
import { type RouteComponentProps } from 'react-router'
import {
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSelectOption,
  IonSpinner,
  IonText,
  useIonRouter
} from '@ionic/react'
import Form from '../../components/Form'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { Fragment, useCallback, useMemo, useState } from 'react'
import FormSelectField from '../../components/Form/FormSelectField'
import getAge from '../../lib/getAge'
import { addCircle, arrowForward, checkmarkCircle, chevronDown, ellipsisVertical, newspaper, person, trashBin, saveOutline, pencil, save } from 'ionicons/icons'
import Participant from '../../components/Camps/Participant'
import { useUser } from '../../providers/Auth/hooks'
import Divider from '../../components/Divider'
import useToast from '../../providers/Toast/hooks'
import CollapsibleContent from '../../components/CollapsibleContent'
import clsx from 'clsx'
import GroupCard from '../../components/Camps/GroupCard'
import Protected from '../../components/Protected'
import FormTextField from '../../components/Form/FormTextField'
import ContextMenuItem from '../../components/ContextMenu/ContextMenuItem/ContentMenuItem'
import ContextMenu from '../../components/ContextMenu'
import FormSlider from '../../components/Form/FormSlider'
import Buttons from '../../components/Buttons'
import FormImageInput from '../../components/Form/FormImageInput'
import FormBirthdateField from '../../components/Form/FormBirthdateField'
import { Capacitor } from '@capacitor/core'
import { endOfYear, format, startOfYear } from 'date-fns'
import ParticipantFormModal from '../../components/Modal/Participant/page'
import { FSC_ACTIVE } from '../../lib/apollo/config'
import useCSVFileImport from '../../lib/hooks/useCSVFileImport'

const dateFormat = new Intl.DateTimeFormat('de-DE', { dateStyle: 'short' })

export interface CampsProps
  extends RouteComponentProps<{
    id: string
  }> {}

const sortGroups = (a: Group, b: Group) => {
  const yearsA = a.participants?.map(participant => getAge(participant.birthday)).filter(Boolean)
  const yearsB = b.participants?.map(participant => getAge(participant.birthday)).filter(Boolean)

  if (!yearsA[0]) return 1
  if (!yearsB[0]) return -1

  if (yearsA[0] === yearsB[0]) {
    return (yearsA[yearsA.length - 1] ?? 0) - (yearsB[yearsB.length - 1] ?? 99)
  }

  return (yearsA[0] ?? 0) - (yearsB[0] ?? 0)
}

const CampPage: React.FC<CampsProps> = ({ match }) => {
  const { t } = useTranslation()
  const user = useUser()
  const [present] = useToast()
  const ionRouter = useIonRouter()
  const [importParticipantsMutation] = useImportParticipantsMutation()
  const [ParticipantFileInput, openParticipantFileDialog] = useCSVFileImport((data) => {
    importParticipantsMutation({
      variables: {
        campId: match.params.id,
        input: data
      },
      refetchQueries: [{
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }]
    })
  })

  const [importTrainersMutation] = useImportTrainersMutation()
  const [TrainersFileInput, openTrainersFileDialog] = useCSVFileImport((data) => {
    importTrainersMutation({
      variables: {
        campId: match.params.id,
        input: data
      },
      refetchQueries: [{
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }]
    })
  })

  const { data } = useCampQuery({
    variables: {
      campId: match.params.id
    },
    fetchPolicy: 'cache-and-network'
  })

  const { data: specialTalentsGql } = useSpecialTalentsQuery({
    variables: {
      campId: match.params.id
    },
    fetchPolicy: 'cache-and-network'
  })

  const { data: markedSpecialTalents } = useMarkedSpecialTalentsQuery({
    variables: {
      campId: match.params.id
    },
    fetchPolicy: 'cache-and-network'
  })

  const specialTalents = useMemo(() =>
    specialTalentsGql?.specialTalents.filter(t => !markedSpecialTalents?.markedSpecialTalents.some(mt => mt.participant.id === t.id))
  , [markedSpecialTalents?.markedSpecialTalents, specialTalentsGql?.specialTalents])

  const markedAsSpecialTalents =
        markedSpecialTalents?.markedSpecialTalents
          .filter(mt => mt.isTalent)
          .map(mt => specialTalentsGql?.specialTalents.find(t => t.id === mt.participant.id))
          .filter(Boolean)

  const markedNotAsSpecialTalents =
        markedSpecialTalents?.markedSpecialTalents
          .filter(mt => !mt.isTalent)
          .map(mt => specialTalentsGql?.specialTalents.find(t => t.id === mt.participant.id))
          .filter(Boolean)

  const [editCampDirectorMutation] = useEditCampDirectorMutation()
  const [editCampDirectorOpen, setEditCampDirectorOpen] = useState(false)
  const editCampDirector = useCallback(async (input: { trainerId: string }) => {
    await editCampDirectorMutation({
      variables: {
        input: {
          ...input,
          campId: match.params.id
        }
      }
    })

    setEditCampDirectorOpen(false)
  }, [editCampDirectorMutation, match.params.id])

  const [newOpen, setNewOpen] = useState(false)
  const [newParticipantOpen, setNewParticipantOpen] = useState(false)
  const [activeParticipant, setActiveParticipant] = useState<GqlParticipant>()
  const defaultParticipantFormState: GqlParticipant = {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    birthday: undefined,
    gender: undefined,
    image: undefined,
    street: '',
    city: '',
    county: '',
    country: '',
    postcode: '',
    voteEnabled: false,
    booking_custom_checkbox_1: false,
    booking_custom_checkbox_2: false,
    talentClass: null
  }

  const [createGroupMutation] = useCreateGroupMutation()
  const createGroup = useCallback(async ({ name }: { name: string }) => {
    await createGroupMutation({
      variables: {
        input: {
          campId: match.params.id,
          name
        }
      },
      refetchQueries: [{
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }]
    })
    setNewOpen(false)
  }, [createGroupMutation, match.params.id])

  const myGroups = useMemo(() => {
    return [...data?.camp?.groups ?? []].sort(sortGroups).filter(g => g.trainer?.email === user?.user.email)
  }, [data?.camp?.groups, user?.user.email, data])

  const groups = useMemo(() => {
    return [...data?.camp?.groups ?? []].sort(sortGroups).filter(g => !myGroups.some(m => m.id === g.id))
  }, [data?.camp?.groups, myGroups])

  const { data: participants, loading } = useParticipantsQuery({
    variables: {
      campId: match.params.id
    }
  })

  const participantsWithoutGroupCount = useMemo(() => {
    return participants?.participants?.filter(p =>
      !data?.camp?.groups?.some(g => g.participants?.find(participant => participant.id === p.id))
    )?.length ?? 0
  }, [participants?.participants, data?.camp?.groups])

  const [talentIds, setTalentIds] = useState<string[]>([])
  const [addOpen, setAddOpen] = useState(false)
  const [selectGroupToAddOpen, setSelectGroupToAddOpen] = useState(false)
  const onTalentItemCLick = useCallback((talentId: string) => {
    if (talentIds.includes(talentId)) {
      setTalentIds(talentIds.filter(id => id !== talentId))
    } else {
      setTalentIds([...talentIds, talentId])
    }
  }, [talentIds])

  const [addTalents] = useAddMultipleToGroupMutation({
    refetchQueries: [{
      query: CampDocument,
      variables: {
        campId: match.params.id
      }
    }]
  })

  const onAddTalentsClick = useCallback(async (group: Group) => {
    await addTalents({
      variables: {
        groupId: group.id,
        campId: match.params.id,
        participantIds: talentIds
      }
    })
    present('Teilnehmer hinzugefügt', 'success')
    setSelectGroupToAddOpen(false)
  }, [addTalents, match.params.id, present, talentIds])

  const groupedParticipantsByAge = useMemo(() => {
    if (!participants?.participants) return {}

    const groupedParticipantsByAge: Record<string, Array<GqlParticipant & { group: string }>> = {}

    const sortedByName = [...participants?.participants ?? []].sort(
      (a, b) => {
        if (a.firstname.localeCompare(b.firstname) === 0) {
          return a.lastname.localeCompare(b.lastname)
        }
        return a.firstname.localeCompare(b.firstname)
      }
    )

    sortedByName.forEach((participant) => {
      const age = getAge(participant.birthday)
      if (!age) return
      // check if participant is already in group

      // check if participant is already in a group
      const groupName = data?.camp?.groups?.find(group => group.participants.find(p => p.id === participant.id))?.name
      if (groupName) return

      if (!groupedParticipantsByAge[age]) {
        groupedParticipantsByAge[age] = []
      }

      groupedParticipantsByAge[age].push({
        ...participant,
        group: groupName ?? ''
      })
    })

    return groupedParticipantsByAge
  }, [data?.camp?.groups, participants?.participants])

  const onParticipantInfoClick = useCallback(() => {
    if ((data?.camp?.groups?.length ?? 0) > 0) {
      setAddOpen(true)
    } else {
      present('Bitte erstelle erst eine Gruppe', 'warning')
    }
  }, [data?.camp?.groups?.length, present])

  const [talentsOpen, setTalentsOpen] = useState(false)

  const [rerunPersistCertificate, { loading: rerunLoading }] = useRerunPersistCertificateMutation()

  const onRegenerateCertificatesClick = useCallback(async () => {
    const campId = data?.camp?.id
    if (!campId) return

    const response = await rerunPersistCertificate({
      variables: {
        campId: +campId
      }
    })
    if (response) {
      present('Zeugnisse erfolgreich generiert', 'success')
    } else {
      present('Oops, irgendwas ist schief gelaufen...', 'danger')
    }
  }, [data?.camp?.id, present, rerunPersistCertificate])

  const [newOpenEditCamp, setNewOpenEditCamp] = useState(false)
  const [editCampMutation] = useEditCampMutation()
  const [ageMin, setMinAge] = useState(data?.camp?.ageMin)
  const [ageMax, setMaxAge] = useState(data?.camp?.ageMax)
  const [preview, setPreview] = useState<string | undefined>()

  const handleMinAgeChange = (event: any) => {
    const newValue = event.detail.value
    setMinAge(newValue)
    return ageMin
  }

  const handleMaxAgeChange = (event: any) => {
    const newValue = event.detail.value
    setMaxAge(newValue)
    return ageMax
  }

  const editCamp = useCallback(({ ageMax, ageMin, caption, start, end, image, number, type }: { ageMax?: number, ageMin?: number, caption?: string, end?: string, image?: string, number?: string, start?: string, type?: string }) => {
    editCampMutation({
      variables: {
        input: {
          ageMax: Number(ageMax),
          ageMin: Number(ageMin),
          caption,
          end,
          image,
          number,
          start,
          type
        },
        campId: match.params.id
      },
      refetchQueries: [{
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }]
    }
    )
    setNewOpenEditCamp(false)
  }, [editCampMutation, ageMin, ageMax])

  const [deleteCamp] = useDeleteCampMutation()
  const today = startOfYear(new Date())
  const endDate = endOfYear(new Date())

  const onDeleteClick = useCallback(async () => {
    await deleteCamp({
      variables: {
        campId: data?.camp?.id ?? '999'
      },
      refetchQueries: [{
        query: CampsDocument,
        variables: {
          start: format(today, 'yyyy-MM-dd'),
          end: format(endDate, 'yyyy-MM-dd')
        }
      }]
    })
    ionRouter.push('/admin/camps')
  }, [deleteCamp, data?.camp])

  const [addParticipant] = useAddParticipantMutation({
    refetchQueries: [{
      query: CampDocument,
      variables: {
        campId: match.params.id
      }
    }]
  })
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const onAddParticipantClick = useCallback(({ firstname, lastname, birthday, booking_custom_checkbox_1, booking_custom_checkbox_2, gender, image, talentClass, voteEnabled, street, city, country, postcode, email }: { firstname: string, lastname: string, birthday: string, booking_custom_checkbox_1: boolean, booking_custom_checkbox_2: boolean, gender: any, image: string, talentClass: any, voteEnabled: boolean, street: string, city: string, country: string, postcode: string, email: string }) => {
    addParticipant({
      variables: {
        campId: match.params.id,
        input: {
          firstname,
          lastname,
          birthday,
          booking_custom_checkbox_1,
          booking_custom_checkbox_2,
          gender,
          image,
          talentClass: Number(talentClass),
          street,
          city,
          country,
          postcode,
          email
        }
      },
      refetchQueries: [{
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      },
      {
        query: ParticipantsDocument,
        variables: {
          campId: match.params.id
        }
      }
      ]
    })
    setNewParticipantOpen(false)
    // window.location.reload()
  }, [addParticipant, match.params.id])

  const [removeAllParticipants] = useRemoveAllParticipantsFromCampMutation()

  const onRemoveAllParticipantsClick = useCallback(() => {
    removeAllParticipants({
      variables: {
        campId: match.params.id
      },
      refetchQueries: [{
        query: ParticipantsDocument,
        variables: {
          campId: match.params.id
        }
      },
      {
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }]
    })
  }, [removeAllParticipants, match.params.id])

  const [removeParticipant] = useRemoveParticipantMutation()

  const onRemoveParticipantClick = useCallback((id: string) => {
    removeParticipant({
      variables: {
        campId: match.params.id,
        participantId: id
      },
      refetchQueries: [{
        query: ParticipantsDocument,
        variables: {
          campId: match.params.id,
          participantId: id
        }
      },
      {
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }]
    })
  }, [removeParticipant, match.params.id])

  const [editParticipantMutation] = useEditParticipantMutation()
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const onEditParticipantOnClick = useCallback(({ id, firstname, lastname, birthday, booking_custom_checkbox_1, booking_custom_checkbox_2, gender, image, talentClass, voteEnabled, street, city, country, postcode, email }: { id: string, participant: GqlParticipant, firstname: string, lastname: string, birthday: string, booking_custom_checkbox_1: boolean, booking_custom_checkbox_2: boolean, gender: any, image: string, talentClass: any, voteEnabled: boolean, street: string, city: string, country: string, postcode: string, email: string }) => {
    editParticipantMutation({
      variables: {
        participantId: id,
        input: {
          firstname,
          lastname,
          birthday,
          booking_custom_checkbox_1,
          booking_custom_checkbox_2,
          gender,
          image,
          talentClass: Number(talentClass),
          street,
          city,
          country,
          postcode,
          email
        }
      },
      refetchQueries: [{
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }, {
        query: ParticipantsDocument,
        variables: {
          campId: match.params.id
        }
      }]
    })
    setNewParticipantOpen(false)
  }, [editParticipantMutation, match.params.id])

  const [newTrainerOpen, setNewTrainerOpen] = useState(false)
  const [activeTrainer, setActiveTrainer] = useState<Trainer>()
  const defaultTrainerFormState: Trainer = {
    id: '',
    firstname: '',
    lastname: '',
    email: ''
  }
  const [addTrainerMutation] = useAddTrainerMutation()
  const onAddTrainerOnClick = useCallback(async ({ firstname, lastname, email }: { firstname: string, lastname: string, email: string }) => {
    await addTrainerMutation({
      variables: {
        campId: match.params.id,
        input: {
          firstname,
          lastname,
          email
        }
      },
      refetchQueries: [{
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }]
    })
    setNewTrainerOpen(false)
  }, [addTrainerMutation, match.params.id])

  // TO DO Add this back if needed. Be aware we need to make the form re-usable by both add and edit Trainer.

  const [editTrainertMutation] = useEditTrainerInCampMutation()
  const onEditTrainerOnClick = useCallback(({ firstname, lastname, email }: { firstname: string, lastname: string, email: string }) => {
    editTrainertMutation({
      variables: {
        campId: match.params.id,
        input: {
          firstname,
          lastname,
          email
        }
      },
      refetchQueries: [{
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }]
    })
    setNewTrainerOpen(false)
  }, [editTrainertMutation, match.params.id])

  const [removeTrainer] = useRemoveTrainerFromCampMutation()

  const removeTrainerOnClick = useCallback(async (email: string) => {
    await removeTrainer({
      variables: {
        campId: match.params.id,
        email
      },
      refetchQueries: [{
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }]
    })
  }, [removeParticipant, match.params.id])

  if (!data?.camp) return null

  return (
        <Page>
            <Header title={data?.camp?.caption ?? t('title.camp') }></Header>
            <Content>
                <div className='camp__info'>
                    <div>
                        <img src={data.camp.image ?? ''} alt={data.camp.caption ?? 'camp-img'} />
                        {!FSC_ACTIVE && <Protected role={'admin'}>
                          <Button
                            onClick={() => { setNewOpenEditCamp(true) } }
                            icon={pencil}
                            >
                            {t('camp.edit')}
                          </Button>
                          <Button
                            alertText={'Are you sure you want to delete this'}
                            onClick={() => { onDeleteClick() } }
                            icon={trashBin}
                            >
                            {t('camp.delete')}
                          </Button>
                        </Protected>}
                    </div>
                    <div>
                        <h2>
                            {data.camp.caption}
                        </h2>
                        <div className='camp__info__conent'>
                            <div>{data.camp.type}</div>
                            <div>{dateFormat.format(new Date(data.camp.start ?? ''))} - {dateFormat.format(new Date(data.camp.end ?? ''))}</div>
                            <div>{data.camp.ageMin} - {data.camp.ageMax} Jahre</div>
                            <div>{data.camp.participantsCount} Teilnehmer</div>
                            {!FSC_ACTIVE && <Protected role={'admin'}>
                                <Button
                                    loading={rerunLoading}
                                    onClick={onRegenerateCertificatesClick}
                                    disabled={!data.camp.finished}
                                    icon={newspaper}
                                >
                                    Zeugnisse erneut generieren
                                </Button>
                            </Protected>}
                            <div>
                                <div className='camp__trainer-list'>
                                    {(data.camp.campDirector
                                      ? (
                                        <IonCard className='trainer-card trainer-card--director ion-margin-top ion-no-margin'>
                                            <IonIcon src='/assets/icon/trainer.svg' size='large' color='secondary' />
                                            <div className='participant-card__caption'>
                                                Campleiter:<br/>{data.camp.campDirector.firstname} {data.camp.campDirector.lastname}
                                            </div>
                                            <Button className='trainer-card__button' icon={ellipsisVertical} fill='clear' color='secondary' onClick={() => { setEditCampDirectorOpen(true) }}/>
                                        </IonCard>
                                        )
                                      : (
                                          <IonCard className='trainer-card trainer-card--director trainer-card--add' onClick={() => { setEditCampDirectorOpen(true) }}>
                                              <IonIcon icon={addCircle} size='large' />
                                              <div className='participant-card__caption participant-card__caption--select'>Campleiter wählen</div>
                                          </IonCard>
                                        ))}
                                </div>
                            </div>
                            {participantsWithoutGroupCount === 0
                              ? (
                                <div className='camp__info__content__participant-info'>
                                    <IonIcon color='success' icon={checkmarkCircle}/>
                                    {t('camp.participants.groupFinished')}
                                </div>
                                )
                              : (
                                <Button className='camp__info__content__participant-info' fill='clear' color='secondary' onClick={onParticipantInfoClick}>
                                    {t('camp.participants.needGroup', { count: participantsWithoutGroupCount })}
                                </Button>
                                ) }
                        </div>
                    </div>
                </div>
                {!FSC_ACTIVE && <Protected role={'admin'}>
                  <Divider />
                  <div className='section-description ion-text-justify'>
                    <ContextMenu>
                        <ContextMenuItem label={t('camp.addParticipant.label')} icon={person} onClick={() => { setActiveParticipant(defaultParticipantFormState); setNewParticipantOpen(true) }}/>
                        <ContextMenuItem label={t('camp.importParticipants.label')} icon={saveOutline} onClick={() => { openParticipantFileDialog() }}/>
                        <ContextMenuItem alertText={t('participant.deleteAlert') } label={t('camp.deleteAllParticipants.label')} icon={trashBin} onClick={onRemoveAllParticipantsClick}/>
                      </ContextMenu>
                      <ParticipantFileInput />
                      <h2 className='ion-margin-bottom'>
                      {t('camp.participants.title')}
                      </h2>
                      <IonText
                          className='ion-padding-bottom n2br'
                          color='medium'
                          >
                          {t('camp.allParticipants.description')}
                      </IonText>
                  </div>

                      <div className='camp__participant-list'>
                        {participants?.participants.map((participant) => {
                          return <Fragment key={participant.id}>
                            <IonCard style={{ cursor: 'pointer' }} className='participant-card participant-card--full-width' onClick={() => {}} key={participant.id}>
                              {participant?.image
                                ? (
                                <img className='participant-card__talent-image' src={participant?.image} alt={`${participant?.firstname} ${participant?.lastname}`} />
                                  )
                                : (
                                <div className='participant-card__talent-image participant-card__talent-image--placeholder' />
                                  )}
                              <div className='participant-card__caption'>
                                  {participant.firstname} {participant.lastname} <br />
                                  <span className='participant-card__caption__group'>{participant.id}</span>
                              </div>
                              <div className='ion-align-self-center display-flex ion-justify-content-end' style={{ flexGrow: 1 }}>
                              <ContextMenu>
                                <ContextMenuItem label={'edit'} icon={pencil} onClick={() => { setActiveParticipant(participant); setNewParticipantOpen(true) }}/>
                                <ContextMenuItem alertText={t('participant.deleteAlert') } label={'delete'} icon={trashBin} onClick={() => { onRemoveParticipantClick(participant.id) }}/>
                              </ContextMenu>
                              </div>
                          </IonCard>
                          </Fragment>
                        })
                        }
                    </div>
                <Divider />
                </Protected>}
                {!!data.camp.groups?.length && (
                    <div className='camp__group-list'>
                        {myGroups.length > 0 && (
                            <>
                                {myGroups.map((group) => <GroupCard key={group.id} group={group} campId={match.params.id} />)}
                                <Divider />
                            </>
                        )}
                        {groups.map((group) => <GroupCard key={group.id} group={group} campId={match.params.id} />)}
                    </div>
                )}
                {
                    <>
                        <div>
                            <div className='section-description ion-text-justify'>
                                <h2
                                    className='ion-margin-bottom'
                                >
                                    {t('camp.createGroup.title')}
                                </h2>
                                <IonText
                                    className='ion-padding-bottom n2br'
                                    color='medium'
                                    >
                                    {t('camp.createGroup.description')}
                                </IonText>
                            </div>
                            <IonCard onClick={() => { setNewOpen(true) }} className='camp__add-group'>
                                <IonCardContent className='camp__group-card'>
                                    <div><IonIcon icon={addCircle} /></div>
                                    <div>Neue Gruppe erstellen</div>
                                </IonCardContent>
                            </IonCard>
                        </div>
                        <Divider />
                    </>
              }
                {!!specialTalents?.length && (
                    <div>
                        <h3 className='ion-padding-start'>{t('camp.newMarkedTalents')}</h3>
                        <div className='camp__participant-list'>
                            {specialTalents?.map(specialTalent => (
                                <Participant
                                    participant={specialTalent}
                                    campId={match.params.id}
                                    key={specialTalent.id}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {!!markedAsSpecialTalents?.length && (
                    <div>
                        <h4 className='ion-padding-start'>{t('camp.markedTalents')}</h4>
                        <div className='camp__participant-list'>
                            {markedAsSpecialTalents?.map(specialTalent => {
                              if (!specialTalent) return null
                              return (<Participant
                                    participant={specialTalent}
                                    campId={match.params.id}
                                    key={specialTalent.id}
                                />
                              )
                            })}
                        </div>
                    </div>
                )}
                {!!markedNotAsSpecialTalents?.length && (
                    <div>
                        <div
                            className='display-flex ion-justify-content-between ion-align-items-center talents-collapsible-wrapper'
                            onClick={() => { setTalentsOpen(old => !old) }}
                        >
                            <h4 className='ion-padding-start'>
                                {t('camp.rejectedTalents')}
                            </h4>
                            <IonIcon
                                icon={chevronDown}
                                className={clsx({ 'talents-collapsible--open': talentsOpen }, 'talents-collapsible')}
                            />
                        </div>
                        <CollapsibleContent open={talentsOpen}>
                        <div className='camp__participant-list'>
                            {markedNotAsSpecialTalents?.map(specialTalent => {
                              if (!specialTalent) return null
                              return (<Participant
                                        participant={specialTalent}
                                        campId={match.params.id}
                                        key={specialTalent.id}
                                    />
                              )
                            })}
                        </div>
                        </CollapsibleContent>
                    </div>
                )}
                <div>
                {!FSC_ACTIVE &&
                    <Protected role={'admin'}>
                      <ContextMenu>
                        <ContextMenuItem label={t('camp.addTrainer.label')} icon={person} onClick={setNewTrainerOpen}/>
                        <ContextMenuItem label={t('camp.importTrainers.label')} icon={saveOutline} onClick={() => { openTrainersFileDialog() }}/>
                        <ContextMenuItem alertText={t('trainer.deleteAlert') } label={t('camp.deleteAllTrainers.label')} icon={trashBin} onClick={() => { } }/>
                      </ContextMenu>
                      <TrainersFileInput />
                    </Protected>
                }
                    <h3 className='ion-padding-start ion-no-margin'>Trainer</h3>
                    <div className='camp__trainer-list'>
                        {data.camp.trainers?.map(trainer => (
                            <IonCard key={trainer.id} className='trainer-card'>
                                <IonIcon src='/assets/icon/trainer.svg' size='large'/>
                                <div className='trainer__caption'>
                                    {trainer.firstname} {trainer.lastname}
                                    <br/>
                                    <a target='_blank' href={`mailto:${trainer.email}`} rel="noreferrer">{trainer.email}</a>
                                </div>
                                <div className='ion-align-self-center display-flex ion-justify-content-end' style={{ flexGrow: 1 }}></div>
                                  <ContextMenu>
                                    <ContextMenuItem label={t('camp.editTrainer.label')} icon={pencil} onClick={ () => { setActiveTrainer(trainer); setNewTrainerOpen(true) }} />
                                    <ContextMenuItem alertText={t('trainer.deleteAlert') } label={t('camp.deleteTrainer.label')} icon={trashBin} onClick={() => { removeTrainerOnClick(trainer.email) }}/>
                                  </ContextMenu>
                                <div/>
                            </IonCard>
                        ))}
                    </div>
                </div>
            </Content>
            <Modal
                modalTitle={t('campModal.edit.title')}
                size='alert'
                isOpen={newOpenEditCamp}
                onClose={() => { setNewOpenEditCamp(false) }}
                onDidDismiss={() => { setNewOpenEditCamp(false) }}
            >
                <div className='create-group-modal'>
                    <Form onSubmit={editCamp}>
                      <FormSlider
                          key={`min_ ${Date.now()}`}
                          onChange={(e) => { handleMinAgeChange(e) } }
                          defaultValue={data.camp.ageMin ?? 0}
                          color='primary'
                          label={t('camp.edit.minAgeInput.label')}
                          info={ t('camp.edit.maxAgeInput.tip') }
                          name='ageMin'
                          min={4}
                          max={18}
                          required
                      />
                  <h3
                      className='talent-vote-form__value'
                  >
                  {ageMin ?? data.camp.ageMin}
                  </h3>
                  <FormSlider
                          key={`max_ ${Date.now()}`}
                          onChange={(e) => { handleMaxAgeChange(e) } }
                          defaultValue={data.camp.ageMin ?? 0}
                          color='primary'
                          label={t('camp.edit.maxAgeInput.label')}
                          info={t('camp.edit.maxAgeInput.tip') }
                          name='ageMax'
                          min={4}
                          max={18}
                          required
                      />
                  <h3
                      className='talent-vote-form__value'
                  >
                  {ageMax ?? data.camp.ageMax}
                  </h3>
                      <Buttons>
                        {/* TO DO: How do we reload existing picture? its not obvious if any image is already there. Maybe this is not needed? */}
                        <FormImageInput
                            name='image'
                            label={Capacitor.isNativePlatform() ? 'make image' : 'upload image' }
                            size={{ width: 800, height: 800 }}
                            onPreviewChange={p => {
                              setPreview(p)
                            }}
                            showPreview={false}
                            avatarWrapperClassName='avatar-wrapper--for-talent'
                        />
                      <Button
                          type='submit'
                          icon={save}
                          disabled={!preview}
                      >
                          Speichern
                      </Button>
                      </Buttons>
                      <FormBirthdateField
                                              name='start'
                                              label={t('camp.edit.start.label')}
                                              defaultValue={data.camp.start}
                                          />
                      <FormBirthdateField
                                              name='end'
                                              label={t('camp.edit.end.label')}
                                              defaultValue={data.camp.end}
                                          />
                      <FormTextField defaultValue={data.camp.caption} name='caption' label={t('camp.edit.start.label')} required />
                      <FormTextField defaultValue={data.camp.number} name='number' label={t('camp.edit.number.label')} required />
                      <FormTextField defaultValue={data.camp.type} name='type' label={t('camp.edit.type.label')}required />
                      <Button type='submit' className='ion-float-end'>Speichern</Button>
                    </Form>
                </div>
            </Modal>
            <Modal
                modalTitle='Gruppe erstellen'
                size='alert'
                isOpen={newOpen}
                onClose={() => { setNewOpen(false) }}
                onDidDismiss={() => { setNewOpen(false) }}
            >
                <div className='create-group-modal'>
                    <Form onSubmit={createGroup}>
                        <FormTextField name='name' label={t('camp.createGroup.label')} required />
                        <Button type='submit' className='ion-float-end'>Speichern</Button>
                    </Form>
                </div>
            </Modal>
            <Modal
                modalTitle='Campleiter wählen'
                size='alert'
                isOpen={editCampDirectorOpen}
                onDidDismiss={() => { setEditCampDirectorOpen(false) }}
            >
                <Form onSubmit={editCampDirector}>
                    <FormSelectField required label='Trainer' name='trainerId' className='jmt-select'>
                        {data.camp.trainers?.map((t) => (
                            <IonSelectOption
                                value={t.id}
                                key={t.id}
                            >
                                {t.firstname} {t.lastname}
                            </IonSelectOption>
                        ))}
                    </FormSelectField>
                    <Button type='submit' className='ion-float-end'>Speichern</Button>
                </Form>
            </Modal>
            <Modal
                modalTitle='Teilnehmer auswählen'
                isOpen={addOpen}
                onClose={() => { setAddOpen(false) }}
                onDidDismiss={() => { setAddOpen(false) }}
            >
                <div className='ion-padding-start'>
                    <IonText color='medium'>
                        {t('camp.addParticipantInfo')}
                    </IonText>
                </div>
                {loading && (
                    <div className='ion-justify-content-center display-flex'>
                        <IonSpinner />
                    </div>
                )}
                {Object.entries(groupedParticipantsByAge ?? {}).map(([age, participants]) => (
                    <div key={age} className='ion-margin-bottom ion-padding-bottom'>
                        <h3>{age} Jahre</h3>
                        <div className='camp__group-list'>
                            {participants.map((participant) => (
                                <IonCard style={{ cursor: 'pointer' }} className='participant-card participant-card--full-width' onClick={() => { onTalentItemCLick(participant.id) }} key={participant.id}>
                                    <IonIcon src='/assets/icon/logo.svg' size='large'/>
                                    <div className='participant-card__caption'>
                                        {participant.firstname} {participant.lastname} <br />
                                        <span className='participant-card__caption__group'>{participant.group}</span>
                                    </div>
                                    <div className='ion-align-self-center display-flex ion-justify-content-end' style={{ flexGrow: 1 }}>
                                        <IonCheckbox checked={talentIds.includes(participant.id)} onClick={(e) => { e.preventDefault() }}/>
                                    </div>
                                </IonCard>
                            ))}
                        </div>
                    </div>
                ))}
                <IonFab vertical='bottom' horizontal='end' slot='fixed'>
                    <IonFabButton onClick={() => {
                      setSelectGroupToAddOpen(true)
                      setAddOpen(false)
                    }}>
                        <IonIcon icon={arrowForward}/></IonFabButton>
                </IonFab>
            </Modal>
            <Modal
                modalTitle='Gruppe auswählen'
                isOpen={selectGroupToAddOpen}
                onClose={() => { setSelectGroupToAddOpen(false) }}
                onDidDismiss={() => { setSelectGroupToAddOpen(false) }}
            >
                <div className='ion-padding-start ion-margin-start'>
                    <IonText color='medium'>
                        {t('camp.selectGroupToAdd')}
                    </IonText>
                </div>
                {data?.camp.groups?.map((group) => <GroupCard key={group.id} group={group} campId={match.params.id} onClick={onAddTalentsClick}/>)}
            </Modal>
          <Button onClick={() => { setNewParticipantOpen(true) }}>Participant</Button>
          <ParticipantFormModal
            isOpen={newParticipantOpen}
            participant={activeParticipant}
            onDidDismiss={() => { setNewParticipantOpen(false); setActiveParticipant(defaultParticipantFormState) }}
            onAddorEditParticipantClick={ activeParticipant?.id ? onEditParticipantOnClick : onAddParticipantClick }
          />
          <Modal
              modalTitle={t('TrainerModal.title')}
              isOpen={newTrainerOpen}
              onClose={() => { setNewTrainerOpen(false) }}
              onDidDismiss={() => { setNewTrainerOpen(false); setActiveTrainer(defaultTrainerFormState) }}
            >
          <div className='ion-padding-start'>
            <Form onSubmit={activeTrainer?.id ? onEditTrainerOnClick : onAddTrainerOnClick }>
              <FormTextField disabled defaultValue={activeTrainer?.email} name='email' label={t('camp.createTrainerEmail.label')} required />
              <FormTextField defaultValue={activeTrainer?.firstname} name='firstname' label={t('camp.createTrainerFirstName.label')} required />
              <FormTextField defaultValue={activeTrainer?.lastname} name='lastname' label={t('camp.createTrainerLastName.label')} required />
              <Button type='submit' className='ion-float-end'>Speichern</Button>
            </Form>
          </div>
            </Modal>
        </Page>
  )
}

export default CampPage
