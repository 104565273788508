import './CampCard.scss'
import { type Camp } from '../../lib/apollo/types'
import { IonCard, IonCardContent, IonCardTitle } from '@ionic/react'
import CampDispositionButton from './CampDispositionButton'
import { DISPOSITIONS_ACTIVE } from '../../lib/apollo/config'

interface CampCardProps {
  camp: Camp
}

const dateFormat = new Intl.DateTimeFormat('de-DE', { dateStyle: 'short' })

const CampCard = ({ camp }: CampCardProps) => {
  const caption = camp.caption ? { __html: camp.caption.replaceAll('/', '/<wbr>') } : undefined

  return (
        <div className='camp-card'>
            <IonCard className='camp-card' routerLink={`/camps/${camp.id}`}>
                <img src={camp.image ?? ''} alt={camp.caption ?? 'camp-img'} />
                <IonCardTitle mode='md'>
                    <span lang="de" dangerouslySetInnerHTML={caption} />
                </IonCardTitle>
                <IonCardContent className='camp-card__conent'>
                    <div>{camp.type}</div>
                    <div>{dateFormat.format(new Date(camp.start ?? ''))} - {dateFormat.format(new Date(camp.end ?? ''))}</div>
                    <div>{camp.ageMin} - {camp.ageMax} Jahre</div>
                    <div>{camp.participantsCount} Teilnehmer</div>
                </IonCardContent>
                {DISPOSITIONS_ACTIVE && (
                    <div className="camp-card__action">
                        <CampDispositionButton camp={camp}/>
                    </div>
                )}
            </IonCard>
        </div>
  )
}

export default CampCard
