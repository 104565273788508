import './style.scss'
import { football } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type Participant } from '../../lib/apollo/types'
import useToast from '../../providers/Toast/hooks'
import Button from '../Button'
import Buttons from '../Buttons'
import Form from '../Form'
import FormSlider from '../Form/FormSlider'
import TalentID, { translationKeys } from '../TalentID'

import { IonCheckbox, IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react'
import useOfflineCache from '../../providers/OfflineCache/hooks'
import { TRANSLATION_NS } from '../../lib/apollo/config'

interface TalentVoteFormProps {
  talent?: Participant | null
  talentId: string
  onVote?: () => any
  networkId?: string
  defaultValue?: number[]
  defaultIsTalent?: boolean
}

export const VALUE_TO_LABEL_ARRAY = ['6', '5-', '5', '5+', '4-', '4', '4+', '3-', '3', '3+', '2-', '2', '2+', '1-', '1', '1+']
export const LABEL_TO_VALUE_FACTOR = 100 / 15

const TalentShortVoteForm: React.FC<TalentVoteFormProps> = ({ talent, talentId, onVote, networkId, defaultValue, defaultIsTalent = false }) => {
  const { t } = useTranslation()
  const { t: clubTrans } = useTranslation(TRANSLATION_NS)
  const [present] = useToast()

  const [rating, setRating] = useState<number[]>(defaultValue ?? [0, 0, 0, 0, 0, 0, 0, 0])
  const [isTalent, setIsTalent] = useState<boolean>(defaultIsTalent)
  const [talentClass, setTalentClass] = useState<'1' | '2'>((talent?.talentClass ?? 1 + '') as '1' | '2' ?? '1')

  const zeroCount = rating.reduce((acc, n) => n === 0 ? acc + 1 : acc, 0)

  const { addVote } = useOfflineCache()

  const doVote = useCallback(async (values: number[], isTalent: boolean) => {
    addVote({
      values,
      isTalent,
      talentId,
      networkId,
      class: +talentClass
    })

    present(t('talent.voteComplete', { name: talent?.firstname }), 'success')
    onVote?.()
  }, [addVote, talentId, networkId, talentClass, present, t, talent?.firstname, onVote])

  const getPinLabel = useCallback((value: number) => VALUE_TO_LABEL_ARRAY[+value.toFixed(0)], [])

  const onSubmit = () => {
    if (zeroCount > 0) {
      present(t('talent.voteZeroCount'), 'warning')
      return
    }

    doVote(rating, isTalent)
  }

  return (
        <>
            <IonGrid>
                {!talent?.talentClass && (
                    <IonRow>
                        <IonCol
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <Button
                                color={talentClass === '1' ? 'primary' : 'light'}
                                onClick={() => { setTalentClass('1') }}
                                icon={football}
                            >
                                Feldspieler
                            </Button>
                            <Button
                                color={talentClass === '2' ? 'primary' : 'light'}
                                onClick={() => { setTalentClass('2') }}
                                iconSrc='/assets/icon/goalie.svg'
                            >
                                Torwart
                            </Button>
                        </IonCol>
                    </IonRow>
                )}
            </IonGrid>
            <Form
                className='talent-vote-form talent-hort-vote-form'
                onSubmit={onSubmit}
            >
                <div
                    className='talent-vote-form__wrapper'
                >
                    <div
                        className='talent-vote-form__talent-id-wrapper-short'
                    >
                        <TalentID
                            values={rating}
                            talentClass={talentClass}
                        />
                    </div>
                    {[0, 1, 2, 3, 4, 5, 6, 7].map((value) => (
                        <div
                            key={value}
                            className='talent-vote-form__slider-wrapper talent-short-vote-form__slider-wrapper'
                        >
                                <FormSlider
                                    key={`${talentId} ${value}`}
                                    onChange={(e) => {
                                      setRating((prev) => {
                                        const next = [...prev]
                                        next[value] = +e.detail.value
                                        return next
                                      })
                                    }}
                                    defaultValue={rating[value]}
                                    color='primary'
                                    label={clubTrans(translationKeys(talentClass)[value])}
                                    info={clubTrans(`${translationKeys(talentClass)[value]}_tip`) }
                                    name={`${value}`}
                                    step={1}
                                    min={0}
                                    max={100}
                                    required
                                />
                            <h3
                                className='talent-vote-form__value'
                            >
                                {getPinLabel(rating[value] / LABEL_TO_VALUE_FACTOR)}
                            </h3>
                        </div>
                    ))}
                    <div
                        className='form-checkbox'
                    >
                        <IonItem
                            lines='none'
                            onClick={() => { setIsTalent(!isTalent) }}
                        >
                            <IonCheckbox
                                slot='start'
                                color='primary'
                                checked={isTalent}
                                onIonChange={(e) => { setIsTalent(e.detail.checked) }}
                            />
                            <IonLabel>
                                {t('talent.specialTalentLabel')}
                            </IonLabel>
                        </IonItem>
                    </div>
                    <Buttons>
                        <Button
                            className='talent-vote-form__preview-btn'
                            fill='solid'
                            color='primary'
                            type='submit'
                            icon={football}
                        >
                            {t('talent.vote')}
                        </Button>
                    </Buttons>
                </div>
            </Form>
        </>
  )
}

export default TalentShortVoteForm
