import { useState } from 'react'
import { IonSelectOption } from '@ionic/react'
import Form from '../../Form'
import FormImageInput from '../../Form/FormImageInput'
import FormBirthdateField from '../../Form/FormBirthdateField'
import FormTextField from '../../Form/FormTextField'
import FormCheckbox from '../../Form/FormCheckbox'
import FormSelectField from '../../Form/FormSelectField'
import { Capacitor } from '@capacitor/core'
import { Gender } from '../../../lib/apollo/types'
import Modal from '..'
import Button from '../../Button'
import { useTranslation } from 'react-i18next'
import { type UseFormReturn } from 'react-hook-form'
import Buttons from '../../Buttons'
import { save } from 'ionicons/icons'

interface ParticipantFormModalProps {
  isOpen: boolean
  onDidDismiss: () => void
  onAddorEditParticipantClick: (e: any, formCtx: UseFormReturn) => any
  participant?: any
}

const ParticipantFormModal: React.FC<ParticipantFormModalProps> = ({
  isOpen,
  onDidDismiss,
  onAddorEditParticipantClick,
  participant = {}
}) => {
  const { t } = useTranslation()
  const [preview, setPreview] = useState<string | undefined>()

  return (
    <Modal
      modalTitle={t('participantModal.title')}
      size='alert'
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <Form onSubmit={onAddorEditParticipantClick} values={participant}>
        <Button onClick={() => { }} />
        <FormTextField name='firstname' label={t('camp.firstnameParticipant.label')} required />
        <FormTextField name='lastname' label={t('camp.lastnameParticipant.label')} required />
        <FormTextField name='email' defaultValue={participant.email} label={t('camp.emailParticipant.label')} />
        <FormBirthdateField
          name='birthdayParticipant'
          label={t('camp.birthdayParticipant.label')}
          defaultValue={participant.birthday}
        />
        <FormCheckbox name='booking_custom_checkbox_1' label={t('camp.booking1Participant.label')} defaultValue={participant.booking_custom_checkbox_1} />
        <FormCheckbox name='booking_custom_checkbox_2' label={t('camp.booking2Participant.label')} defaultValue={participant.booking_custom_checkbox_2} />
        <FormSelectField label={t('camp.genderParticipant.label')} name='gender'>
          {[Gender.Male, Gender.Female, Gender.Diverse].map((g) => (
            <IonSelectOption value={g} key={`key_${g}`}>
              {g}
            </IonSelectOption>
          ))}
        </FormSelectField>
        <FormTextField name='street' defaultValue={participant.street} label={t('camp.streetParticipant.label')} />
        <FormTextField name='city' defaultValue={participant.city} label={t('camp.cityParticipant.label')} />
        <FormTextField name='country' defaultValue={participant.country} label={t('camp.countryParticipant.label')} />
        <FormTextField name='postcode' defaultValue={participant.postcode} label={t('camp.postcodeParticipant.label')} />
        <Buttons>
          <FormImageInput
            name="image"
            label={Capacitor.isNativePlatform() ? 'make image' : 'upload image'}
            size={{ width: 800, height: 800 }}
            onPreviewChange={(p) => { setPreview(p) }}
            showPreview={false}
            avatarWrapperClassName='avatar-wrapper--for-talent'
        />
        <Button
            type='submit'
            icon={save}
            disabled={!preview}
        >
            {t('button.save')}
        </Button>
        </Buttons>
        <FormTextField type='number' name='talentClass' defaultValue={participant.talentClass} label={t('camp.talentClassParticipant.label')} />
        <Button type='submit' className='ion-float-end'> {t('button.save')} </Button>
        <Button onClick={() => { onDidDismiss() }} className='ion-float-end'>{t('button.close')}</Button>
      </Form>
    </Modal>
  )
}

export default ParticipantFormModal
