// import './AdminCampCard.scss'
import { type Camp } from '../../../lib/apollo/types'
import { IonCard, IonCardContent, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react'
import ContextMenuItem from '../../ContextMenu/ContextMenuItem/ContentMenuItem'
import ContextMenu from '../../ContextMenu'
import { create, trashBin } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

interface AdminCampCardProps {
  camp: Camp
  setEditId: (id: string) => void
  setDeleteId: (id: string) => void
}

const dateFormat = new Intl.DateTimeFormat('de-DE', { dateStyle: 'short' })

const AdminCampCard = ({ camp, setEditId, setDeleteId }: AdminCampCardProps) => {
  const caption = camp.caption ? { __html: camp.caption.replaceAll('/', '/<wbr>') } : undefined
  const { t } = useTranslation()

  return (
        <div className='camp-card'>
            <IonCard className='admin-camp-card' routerLink={`/camps/${camp.id}`} >
              <IonGrid>
                  <IonRow>
                    <IonCol size='4'>
                     <IonCardContent>
                      <img src={camp.image ?? ''} alt={camp.caption ?? 'camp-img'} />
                     </IonCardContent>
                    </IonCol>
                    <IonCol size='6'>
                      <IonCardContent>
                        <IonCardTitle>
                            <span lang="de" dangerouslySetInnerHTML={caption} />
                        </IonCardTitle>
                          <div>{camp.type}</div>
                          <div>{dateFormat.format(new Date(camp.start ?? ''))} - {dateFormat.format(new Date(camp.end ?? ''))}</div>
                          <div>{camp.ageMin} - {camp.ageMax} Jahre</div>
                          <div>{camp.participantsCount} Teilnehmer</div>
                      </IonCardContent>
                    </IonCol>
                    <IonCol size='2' onClick={(e) => {
                      e.stopPropagation()
                    }}>
                      <ContextMenu>
                        {/* TODO: need to create translations here for labels */}
                        <ContextMenuItem label={t('camp.edit')} icon={create} onClick={() => { setEditId(camp.id) }}/>
                        <ContextMenuItem alertText={t('group.deleteAlert') } label={t('camp.delete')} icon={trashBin} onClick={() => { setDeleteId(camp.id) }}/>
                      </ContextMenu>
                    </IonCol>
                  </IonRow>
                </IonGrid>
            </IonCard>
        </div>
  )
}

export default AdminCampCard
