import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import Dashboard from './pages/Dashboard'
import { GlobalLoadingProvider } from './providers/GlobalLoading'
import { type ApolloClient, ApolloProvider, type NormalizedCacheObject } from '@apollo/client'
import apolloClient from './lib/apollo'
import CampPage from './pages/Camp'
import AdminCamps from './pages/Admin/Camps'
import Login from './pages/Login'
import { useEffect, useState } from 'react'
import { NetworkProvider } from './providers/Network'
import TalentPage from './pages/Talent'
import { ToastProvider } from './providers/Toast'
import GroupPage from './pages/Camp/Group'
import CertificatePage from './pages/Certificate'
import CameraPage from './pages/Talent/Camera'
import OfflineCacheProvider from './providers/OfflineCache'
import { StatusBar, Style } from '@capacitor/status-bar'
import TalentVotePage from './pages/Talent/Vote'
import TalentCertificatePage from './pages/Talent/Certificate'
import SharedPage from './pages/Certificate/Shared'
import AppIntro from './components/AppIntro'
import { Capacitor } from '@capacitor/core'
import CertificateCampPage from './pages/Certificate/Camp'
import ProtectedRoute from './components/ProtectedRoute'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import 'swiper/css/bundle'
import 'swiper/css/free-mode'
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/ionic-swiper.css'

/* Theme variables */
import './theme/variables.scss'
import './theme/theme.scss'

setupIonicReact({
  swipeBackEnabled: false
})

if (Capacitor.isNativePlatform()) {
  StatusBar.setStyle({ style: Style.Dark })
}

const App: React.FC = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()

  useEffect(() => {
    const createClient = async () => {
      const c = await apolloClient()
      setClient(c)
    }

    createClient()
  }, [])

  return (
        <IonApp>
            {client && (
                <ApolloProvider
                    client={client}
                >
                    <IonReactRouter>
                        <NetworkProvider>
                            <GlobalLoadingProvider>
                                <ToastProvider>
                                    <OfflineCacheProvider>
                                        <AppIntro>
                                            <IonRouterOutlet>
                                                <Route path="/dashboard" exact>
                                                    <Dashboard/>
                                                </Route>
                                                <ProtectedRoute role="admin" path="/admin/camps" redirectTo="/dashboard" exact component={AdminCamps}/>
                                                <Route path="/camps/:id" exact component={CampPage}/>
                                                <Route path="/camps/:id/group/:groupIndex" exact component={GroupPage}/>
                                                <Route path="/camps/:id/talents/:talentId" exact component={TalentPage}/>
                                                <Route path="/camps/:id/talents/:talentId/vote" exact component={TalentVotePage}/>
                                                <Route path="/camps/:id/talents/:talentId/camera" exact component={CameraPage}/>
                                                <Route path="/camps/:id/talents/:talentId/certificate" exact component={TalentCertificatePage}/>
                                                <Route path="/certificate" exact component={CertificatePage}/>
                                                <Route path="/certificate/camp/:campId" exact component={CertificateCampPage}/>
                                                <Route path="/s/:key" exact component={SharedPage}/>
                                                <Route path="/login" exact>
                                                    <Login/>
                                                </Route>
                                                <Route path="/" exact>
                                                    <Redirect to="/dashboard"/>
                                                </Route>
                                            </IonRouterOutlet>
                                        </AppIntro>
                                    </OfflineCacheProvider>
                                </ToastProvider>
                            </GlobalLoadingProvider>
                        </NetworkProvider>
                    </IonReactRouter>
                </ApolloProvider>
            )}
        </IonApp>
  )
}
export default App
