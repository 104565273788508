import {
  IonCol, IonContent,
  IonGrid, IonLoading,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText, useIonAlert,
  useIonViewWillEnter
} from '@ionic/react'
import Header from '../../../components/Header'
import { useTranslation } from 'react-i18next'
import {
  useCertificateQuery,
  type Certificate as GqlCertificate,
  useShareCertificateMutation
} from '../../../lib/apollo/types'
import Certificate from '../../../components/Certificate'
import { type RouteComponentProps } from 'react-router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Button from '../../../components/Button'
import Buttons from '../../../components/Buttons'
import { checkmarkDone, copy, image, shareSocial } from 'ionicons/icons'
import JoinJMTModal from './JoinJMTModal'
import Modal from '../../../components/Modal'
import { CLUB_SHORT, FE_URL, GRAPHQL_URL, SCHOOL_IMAGE, TRANSLATION_NS } from '../../../lib/apollo/config'

interface CertificateCampPageProps
  extends RouteComponentProps<{
    campId: string
  }> {
}

const API_URL = GRAPHQL_URL.replace('/graphql', '')

const CertificateCampPage: React.FC<CertificateCampPageProps> = ({ match }) => {
  const { t } = useTranslation()
  const { t: clubT } = useTranslation(TRANSLATION_NS)
  const { data } = useCertificateQuery()
  const [present] = useIonAlert()
  useIonViewWillEnter(() => {
    document.body.classList.add('cert')
  })

  const [shareMutation, { data: shareKey, loading }] = useShareCertificateMutation()

  const certificates = useMemo(() => data?.certificate.filter(certificate => certificate.camp.id === match.params.campId),
    [data?.certificate, match.params.campId])

  const [cert, setCert] = useState<GqlCertificate | null>()

  const [open, setOpen] = useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [buttonText, setButtonText] = useState('cert.copyShareLink')

  const onShareClick = useCallback(async () => {
    if (!cert) return

    present({
      header: t('cert.share.hint.title'),
      message: t('cert.share.hint.message'),
      buttons: [
        {
          text: t('buttons.cancel'),
          role: 'cancel'
        },
        {
          text: t('buttons.ok'),
          handler: async () => {
            const { data } = await shareMutation({
              variables: {
                participantId: cert.participantId
              }
            })
            if (data?.shareCertificate) {
              setShareDialogOpen(true)
            }
          }
        }
      ]
    })
  }, [cert, present, shareMutation, t])

  useEffect(() => {
    setCert(certificates?.[0] ?? null)
  }, [certificates])

  return (
        <IonPage className='certificate-page camp cert-page'>
            <Header title={t('title.certificates')}/>
            <IonContent className='camp-wrapper'>
                <IonLoading isOpen={loading} message={t('cert.share.loading') as string}/>
                <div className='certificates'>
                    {(data?.certificate ?? []).length === 0 && (
                        <div className='n2br'>
                            <IonText
                                className='ion-margin-bottom'
                                color='medium'
                            >
                                        <span>
                                            {clubT('certificate.noCertificate')}
                                        </span>
                            </IonText>
                        </div>
                    )}
                    {cert && (certificates?.length ?? 0) > 0 && (
                        <IonSegment color='primary' className='ion-padding-bottom' value={cert?.participantId} mode='md'>
                            {certificates?.map(certificate => (
                                <IonSegmentButton
                                    value={certificate.participantId}
                                    key={certificate.id}
                                    onClick={() => { setCert(certificate) }}
                                >
                                    {certificate.caption}
                                </IonSegmentButton>
                            ))}
                        </IonSegment>
                    )}
                    {cert && (
                        <>
                            <Certificate
                                club={CLUB_SHORT}
                                key={cert.camp.id}
                                certificate={cert}
                                header={{
                                  intro: 'Bei uns bist du der Star'
                                }}
                                footer={{
                                  text: clubT('certificate.footer'),
                                  img: '/assets/football-badge-svgrepo-com.svg'
                                }}
                                clubImg='/assets/icon/logo.svg'
                                schoolImg={SCHOOL_IMAGE}
                            />
                            <IonGrid>
                              <IonRow>
                                <IonCol>
                                  <h1>{clubT('cert.shareWithJoinMyTalent.title')}</h1>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol className='n2br'>
                                  <IonText
                                      color='medium'>{clubT('cert.shareWithJoinMyTalent.subtitle', { name: cert.caption })}</IonText>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol className='ion-padding-bottom ion-margin-bottom'>
                                  <Buttons>
                                    <Button
                                        iconSrc='/assets/logo_jmt.svg'
                                        color='jmt'
                                        onClick={() => {
                                          if (cert?.talentId && cert.talentId !== 'syncing') {
                                            present({
                                              message: t('cert.alreadyJoinedJMT'),
                                              buttons: [
                                                { text: t('buttons.ok') }
                                              ]
                                            })
                                            return
                                          }
                                          setOpen(true)
                                        }}
                                    >
                                      {clubT('cert.transferToJMT')}
                                    </Button>
                                  </Buttons>
                                </IonCol>
                              </IonRow>
                              <IonRow className='ion-margin-top ion-padding-top'>
                                  <IonRow>
                                      <IonCol>
                                          <h3>{t('cert.share.title')}</h3>
                                      </IonCol>
                                  </IonRow>
                                  <IonRow>
                                      <IonCol className='n2br'>
                                          <IonText color='medium'>{clubT('cert.share.description')}</IonText>
                                      </IonCol>
                                  </IonRow>
                                  <Buttons>
                                      <Button
                                          onClick={onShareClick}
                                          icon={shareSocial}
                                      >
                                          {t('cert.share.button')}
                                      </Button>
                                  </Buttons>
                              </IonRow>
                            </IonGrid>
                            <JoinJMTModal open={open} close={() => { setOpen(false) }} certificate={cert}/>
                            <Modal
                                size='alert'
                                isOpen={shareDialogOpen}
                                modalTitle={t('cert.shared') }
                                onClose={() => { setShareDialogOpen(false) }}
                                onDidDismiss={() => { setShareDialogOpen(false) }}
                            >
                                <Buttons
                                    title={clubT('cert.share.title') }
                                    subtitle={clubT('cert.share.subtitle') }
                                >
                                    <Button
                                        icon={buttonText === 'cert.shareLinkCopied' ? checkmarkDone : copy}
                                        onClick={async () => {
                                          await navigator.clipboard.writeText(
                                                `${FE_URL}s/${shareKey?.shareCertificate}`
                                          )
                                          setButtonText('cert.shareLinkCopied')
                                        }}
                                    >
                                        {t(buttonText)}
                                    </Button>
                                    <Button
                                        icon={image}
                                        href={`${API_URL}/static/certificates/${shareKey?.shareCertificate}.jpg`}
                                        target='_blank'
                                    >
                                      {t('cert.showAsImage')}
                                    </Button>
                                </Buttons>
                            </Modal>
                        </>
                    )}
                </div>
            </IonContent>
        </IonPage>
  )
}

export default CertificateCampPage
