import './style.scss'
import { useCampsQuery } from '../../lib/apollo/types'
import CampCard from './CampCard'
import Slider from '../Slider'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { endOfYear, format, startOfYear } from 'date-fns'
import { IonText } from '@ionic/react'

let today = startOfYear(new Date())
const firstJuly = new Date(2023, 6, 1)
if (today < firstJuly) {
  today = firstJuly
}
const endDate = endOfYear(new Date())

const AllCamps: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useCampsQuery({
    variables: {
      start: format(today, 'yyyy-MM-dd'),
      end: format(endDate, 'yyyy-MM-dd')
    },
    fetchPolicy: 'cache-and-network'
  })

  const campsGroupedByYearAndMonth = useMemo(() => {
    const campsGroupedByYearAndMonth: Record<string, any[]> = {}
    data?.camps?.forEach(camp => {
      if (!camp.start) return
      const date = new Date(camp.start)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const key = `${year}-${String(month).padStart(2, '0')}`
      if (!campsGroupedByYearAndMonth[key]) {
        campsGroupedByYearAndMonth[key] = []
      }
      campsGroupedByYearAndMonth[key].push(camp)
    })
    return campsGroupedByYearAndMonth
  }, [data?.camps])

  return (
        <>
            <div className='camps__title'>
                <h2>{t('camps.all.title')}</h2>
                <IonText color='medium'>{t('camps.all.description')}</IonText>
            </div>
            {Object.entries(campsGroupedByYearAndMonth ?? {}).map(([monthYear, camps]) => (
                <Slider title={t(`animation_label.m.${monthYear}`)} itemWidth={600} freeMode={true} key={monthYear}>
                    {camps.map(camp => (
                        <div key={camp.id}>
                        <CampCard camp={camp}/>
                        </div>
                    ))}
                </Slider>
            ))}
        </>
  )
}

export default AllCamps
