import './style.scss'
import { Trans, useTranslation } from 'react-i18next'
import { type CertificateProps } from '../types'
import CertificateTalentCardMedium from '../../CertificateTalentCardMedium'
import { QRCode } from 'react-qrcode-logo'
import getKey from '../getKey'
import { CLUB_IMAGE, SCHOOL_IMAGE, TRANSLATION_NS } from '../../../lib/apollo/config'
import siegel from '../../../assets/img/certified_pos.png'

const BMGCertificate = (props: CertificateProps) => {
  const { t } = useTranslation()
  const { t: clubT } = useTranslation(TRANSLATION_NS)
  const position = props.certificate.talentClass === 2 ? 'goalie.' : ''
  return (
        <>
            <div className='bmg-certificate-wrapper' id='certificate-page-1'>
                <div className="certificate">
                    <div className='certificate__upper'>
                        <div className='certificate__upper__logo hide-big'>
                            <div>
                                <h1>
                                    {clubT('certificate.title')}
                                </h1>
                            </div>
                            <div>
                                <img src={props.clubImg} alt='BMG Logo' className='certificate__texts__logo'/>
                            </div>
                        </div>
                        <div className="certificate__talent-card-wrapper">
                            <CertificateTalentCardMedium
                                certificate={props.certificate}
                                clubImg={CLUB_IMAGE}
                                schoolImg={SCHOOL_IMAGE}
                            />
                        </div>
                        <div className="certificate__texts n2br">
                            <h1 className="hide-small">
                                {clubT('certificate.title')}
                            </h1>
                            <h2>
                                {props.certificate.caption}
                            </h2>
                            <p>
                                <Trans
                                    i18nKey='certificate.thankYou'
                                    ns={TRANSLATION_NS}
                                    values={{ name: props.certificate.camp.caption }}
                                >
                                    certificate.thankYou
                                    <strong>
                                        {props.certificate.camp.caption}
                                    </strong>
                                </Trans>
                            </p>
                            <img alt='siegel' src={siegel} className='certificate__texts__siegel' style={{ height: '90px', minWidth: '90px' }}/>
                            <img src={props.clubImg} alt='Logo' className='certificate__texts__logo hide-small'/>
                        </div>
                    </div>
                    <div className="certificate page-2 hide-big">
                        <h1>Deine Skills</h1>
                        <div className='certificate__values'>
                            <div>
                                {[0, 1, 2, 3].map((i) => (
                                    <div className='certificate__values__block' key={i}>
                                        <h5>{clubT(`i18n.vote.${position}${i}`)} &nbsp;&nbsp; {props.certificate?.vote[i]}</h5>
                                        <div className='certificate__values__block__description'>
                                            <p>
                                                {clubT(`certificate.${i}.${position}${getKey(props.certificate?.vote[i])}`)}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div>
                                {[4, 5, 6, 7].map((i) => (
                                    <div className='certificate__values__block' key={i}>
                                        <h5>{clubT(`i18n.vote.${position}${i}`)} &nbsp;&nbsp; {props.certificate?.vote[i]}</h5>
                                        <div className='certificate__values__block__description'>
                                            <p>
                                                {clubT(`certificate.${i}.${position}${getKey(props.certificate?.vote[i])}`)}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='certificate__footer'>
                        <div className='certificate__footer__logo'>
                            <img src="/assets/bmg/academy.png" alt='Logo' />
                        </div>
                        <div className='certificate__footer__text'>
                            <p>
                                {clubT('certificate.footer')}
                            </p>
                        </div>
                        <div className='certificate__footer__qr'>
                            <QRCode
                                value="https://u.wechat.com/MCo6k3HLR7Q9IgjFEwuwXjA"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bmg-certificate-wrapper' id='certificate-page-2'>
                <div className="certificate page-2 hide-small">
                    <h1>{t('certificate.page2.title')}</h1>
                    <div className='certificate__values'>
                        <div className="certificate__values__col">
                            {[0, 1, 2, 3].map((i) => (
                                <div className='certificate__values__col__block' key={i}>
                                    <h5>{clubT(`i18n.vote.${position}${i}`)} &nbsp;&nbsp; {props.certificate?.vote[i]}</h5>
                                    <div className='certificate__values__block__description'>
                                        <p>
                                            {clubT(`certificate.${i}.${position}${getKey(props.certificate?.vote[i])}`)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="certificate__values__col">
                            {[4, 5, 6, 7].map((i) => (
                                <div className='certificate__values__col__block' key={i}>
                                    <h5>{clubT(`i18n.vote.${position}${i}`)} &nbsp;&nbsp; {props.certificate?.vote[i]}</h5>
                                    <div className='certificate__values__block__description'>
                                        <p>
                                            {clubT(`certificate.${i}.${position}${getKey(props.certificate?.vote[i])}`)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='certificate__footer'>
                        <div className='certificate__footer__logo'>
                            <img src="/assets/bmg/academy.png" alt='Logo'/>
                        </div>
                        <div className='certificate__footer__text'>
                        <p>
                                {clubT('certificate.footer')}
                            </p>
                        </div>
                        <div className='certificate__footer__qr'>
                            <QRCode
                                value="https://u.wechat.com/MCo6k3HLR7Q9IgjFEwuwXjA"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default BMGCertificate
