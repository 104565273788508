import { useTranslation } from 'react-i18next'
import { signOut } from '../../../providers/Auth/hooks'
import Button from '../../../components/Button'
import Buttons from '../../../components/Buttons'
import Page from '../../../components/Page'
import Header from '../../../components/Header'
import Content from '../../../components/Content'
import { logOutOutline, save } from 'ionicons/icons'
import { CLUB_SHORT, FSC_ACTIVE } from '../../../lib/apollo/config'
import Protected from '../../../components/Protected'
import { useState, useCallback } from 'react'
import { useCreateCampMutation, CampsDocument, useImportCampsMutation } from '../../../lib/apollo/types'
import Modal from '../../../components/Modal'
import Form from '../../../components/Form'
import FormTextField from '../../../components/Form/FormTextField'
import FormImageInput from '../../../components/Form/FormImageInput'
import FormSlider from '../../../components/Form/FormSlider'
import { Capacitor } from '@capacitor/core'
import FormBirthdateField from '../../../components/Form/FormBirthdateField'
import useCSVFileImport from '../../../lib/hooks/useCSVFileImport'
import { type RangeChangeEventDetail } from '@ionic/react'
import { endOfYear, format, startOfYear } from 'date-fns'
import AllAdminCamps from '../../../components/Camps/Admin/Index'
import { t } from 'i18next'

const AdminCamps: React.FC = () => {
  const { t: clubT } = useTranslation(CLUB_SHORT)

  const [newOpen, setNewOpen] = useState(false)
  const [createCampMutation] = useCreateCampMutation()
  const [ageMin, setMinAge] = useState<number>(6)
  const [ageMax, setMaxAge] = useState<number>(16)
  const [importCampsMutation] = useImportCampsMutation()

  const [FileInput, openFileDialog] = useCSVFileImport((data) => {
    importCampsMutation({
      variables: {
        input: data
      },
      refetchQueries: [{
        query: CampsDocument,
        variables: {
          start: format(today, 'yyyy-MM-dd'),
          end: format(endDate, 'yyyy-MM-dd')
        }
      }]
    })
  })
  const [preview, setPreview] = useState<string | undefined>()

  const handleMinAgeChange = (event: CustomEvent<RangeChangeEventDetail>) => {
    const newValue = event.detail.value as number
    if (newValue) {
      setMinAge(newValue)
    }
  }

  const handleMaxAgeChange = (event: CustomEvent<RangeChangeEventDetail>) => {
    const newValue = event.detail.value as number
    if (newValue) {
      setMaxAge(newValue)
    }
  }
  const today = startOfYear(new Date())
  const endDate = endOfYear(new Date())

  const createCamp = useCallback(async ({ ageMax, ageMin, caption, start, end, image, number, type }: { ageMax?: number, ageMin?: number, caption?: string, end?: string, image?: string, number?: string, start?: string, type?: string }) => {
    await createCampMutation({
      variables: {
        input: {
          ageMax: Number(ageMax),
          ageMin: Number(ageMin),
          caption,
          end,
          image,
          number,
          start,
          type
        }
      },
      refetchQueries: [{
        query: CampsDocument,
        variables: {
          start: format(today, 'yyyy-MM-dd'),
          end: format(endDate, 'yyyy-MM-dd')
        }
      }]
    }
    )
    setNewOpen(false)
  }, [createCampMutation, ageMin, ageMax])

  return (
        <Page>
            <Header title={clubT('dashboard.title')} mainTitle>
                <Button icon={logOutOutline} onClick={async () => { await signOut() }} />
            </Header>
            <Content>
              <div className='home'>
              { !FSC_ACTIVE &&
              <>
              <Protected role={'admin'}>
                <Button className="ion-start ion-padding-vertical" onClick={() => { setNewOpen(true) }}>{t('camp.button.add')}</Button>
              </Protected>
              <Protected role={'admin'}>
                <Button className="ion-start ion-padding-vertical" onClick={() => { openFileDialog() }}>{t('camp.button.import')}</Button>
                <FileInput />
              </Protected>
               <AllAdminCamps/>
               <Protected role={'admin'}>
                <Button className="ion-start ion-padding-vertical" onClick={() => { setNewOpen(true) }}>{t('camp.button.add')}</Button>
              </Protected>
              </>
              }
              </div>
              <Modal
                modalTitle={t('campModal.add.title')}
                size='alert'
                isOpen={newOpen}
                onClose={() => { setNewOpen(false) }}
                onDidDismiss={() => { setNewOpen(false) }}
            >
                <div className='create-group-modal'>
                    <Form onSubmit={createCamp}>
                      <FormSlider
                          onChange={handleMinAgeChange}
                          defaultValue={ageMin}
                          color='primary'
                          label={t('camp.edit.minAgeInput.label')}
                          info={ t('camp.edit.maxAgeInput.tip') }
                          name='ageMin'
                          min={4}
                          max={18}
                          required
                      />
                  <h3
                      className='talent-vote-form__value'
                  >
                  {ageMin ?? 6}
                  </h3>
                  <FormSlider
                          onChange={handleMaxAgeChange}
                          defaultValue={ageMax}
                          color='primary'
                          label={t('camp.edit.maxAgeInput.label')}
                          info={t('camp.edit.maxAgeInput.tip') }
                          name='ageMax'
                          min={4}
                          max={18}
                          required
                      />
                  <h3
                      className='talent-vote-form__value'
                  >
                  {ageMax ?? 16}
                  </h3>
                      <Buttons>
                        <FormImageInput
                            name='image'
                            label={Capacitor.isNativePlatform() ? 'make image' : 'upload image' }
                            size={{ width: 800, height: 800 }}
                            onPreviewChange={p => {
                              setPreview(p)
                            }}
                            showPreview={false}
                            required
                            avatarWrapperClassName='avatar-wrapper--for-talent'
                        />
                      <Button
                          type='submit'
                          icon={save}
                          disabled={!preview}
                      >
                          {t('button.save')}
                      </Button>
                      </Buttons>
                      <FormBirthdateField
                                              name='start'
                                              label={t('camp.edit.start.label')}
                                              defaultValue={Date.now().toString()}
                                          />
                      <FormBirthdateField
                                              name='end'
                                              label={t('camp.edit.end.label')}
                                              defaultValue={Date.now().toString()}
                                          />
                      <FormTextField name='caption' label={t('camp.edit.start.label')} required />
                      <FormTextField name='number' label={t('camp.edit.number.label')} required />
                      <FormTextField name='type' label={t('camp.edit.type.label')} required />
                      <Button type='submit' className='ion-float-end'> {t('button.save')}</Button>
                    </Form>
                </div>
            </Modal>
            </Content>
        </Page>
  )
}

export default AdminCamps
