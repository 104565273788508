import { type CertificateProps } from './types'
import JMTCertificate from './JMT'
import RBLCertificate from './RBL'
import BMGCertificate from './BMG'
import { Suspense } from 'react'

const Certificate = (props: CertificateProps) => {
  switch (props.club) {
    case 'bmg': return (
        <Suspense fallback='...loading'>
            <BMGCertificate {...props} clubImg="/assets/bmg/logo.svg"/>
        </Suspense>
    )
    case 'rbl': return (
        <Suspense fallback='...loading'>
          <RBLCertificate {...props} clubImg="/assets/rbl/schule_logo.png"/>
        </Suspense>
    )
    default: return <JMTCertificate {...props} />
  }
}

export default Certificate
