import React, {
  type ChangeEvent,
  useCallback,
  useRef
} from 'react'

type OnImportHandler = (data: string) => void

const useCSVFileImport = (importHandler: OnImportHandler) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const openFileDialog = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = (event) => {
      if (event.target?.result) {
        importHandler(event.target.result as string)
      }
    }

    reader.readAsDataURL(file)
  }, [importHandler])

  const FileInput = () =>
    <input onChange={handleFileChange} type="file" accept='.csv,text/csv' ref={fileInputRef} style={{ display: 'none' }} />

  return [FileInput, openFileDialog] as const
}

export default useCSVFileImport
